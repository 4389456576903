import styles from './App.module.less';
import React, {useEffect, useState} from "react";
import {getStore} from "./store";
import {Route, Routes, Navigate, BrowserRouter} from 'react-router-dom'
import init, {getUrlConfig} from "./init";
import {Alert, Skeleton} from "antd";
import pluginRoutes from "./routes/pluginRoutes";
import AuthPlugin from "./pages/AuthPlugin";
import {PLUGIN_ROUTES} from "./utils/env";
import otelApi from "./utils/otel/api";
import {WILLAUTH_PLUGIN_URI} from "./pages/WillAuthPlugin";
import SealPlugin, {SEAL_PLUGIN_URI} from "./pages/SealPlugin";
import WillAuthPlugin from "./pages/WillAuthPlugin";
import {checkPublicPageEnv} from "./pages/PublicPage/service";
import './log';

export const AUTH_PLUGIN_URI = '/auth';

export default function App() {
    const [isInited, setInited] = useState(0);

    useEffect(() => {
        const isPublicPageEnv = checkPublicPageEnv(() => setInited(1))
        if (isPublicPageEnv) return;

        getUrlConfig().then(urls => {
            const inPluginEnv = PLUGIN_ROUTES.includes(window.location.pathname);
            // mobile 跳转
            const ua = navigator.userAgent;
            if (!inPluginEnv && /Mobi|Android|iPhone/i.test(ua) && !window.location.href.includes(SEAL_PLUGIN_URI)) {
                const {newEsignCNh5} = urls;
                window.location.replace(newEsignCNh5 + window.location.search);
                return 0;
            } else {
                // const queryParams = getParams() || {};
                // if (queryParams['go-login']) { // 重新登录
                //     login();
                //     return 0;
                // }
                //
                // if (window.location.href.includes('/result?errCode')) {
                //     return 1;
                // }
                if (window.location.href.includes(AUTH_PLUGIN_URI)) {
                    return 1;
                }
                if (window.location.href.includes(WILLAUTH_PLUGIN_URI)) {
                    return 1;
                }
                if (window.location.href.includes(SEAL_PLUGIN_URI)) {
                    return 1;
                }
                return init();
            }
        }).then(res => {
            if (res === null) {
                return;
            }
            const configUrls = getStore('configUrls') || {};
            otelApi.initOtel(configUrls.otelTraceUrl, 'cloud-editor-tracer', '1.0.0').then();
            setInited(res);
        });
    }, []);

    return (
        <div className={styles.App}>
            <BrowserRouter>
                {
                    isInited < 1 ? (
                        <div className={styles.skeleton}>
                            <Skeleton active loading={!isInited} >
                                {isInited === -1 && <Alert
                                    message="服务端数据错误"
                                    description="请确认请求链接正确或联系平台管理员。"
                                    type="error"
                                    showIcon
                                />}
                            </Skeleton>
                        </div>
                    ) : (
                        <Routes>
                            {/*<Route exact path='/' element={*/}
                            {/*    <RequireAuth permission={2}>*/}
                            {/*        <Main />*/}
                            {/*    </RequireAuth>*/}
                            {/*}></Route>*/}
                            {/*<Route exact path='/info' element={*/}
                            {/*    <RequireAuth permission={0}>*/}
                            {/*        <Cover />*/}
                            {/*    </RequireAuth>*/}
                            {/*}></Route>*/}
                            {/*<Route exact path="/add-mobile" element={*/}
                            {/*    <RequireAuth permission={1}>*/}
                            {/*        <AddMobile/>*/}
                            {/*    </RequireAuth>}*/}
                            {/*/>*/}
                            {/*<Route exact path="/add-email" element={*/}
                            {/*    <RequireAuth permission={1}>*/}
                            {/*        <AddMobile isEmail={true}/>*/}
                            {/*    </RequireAuth>}*/}
                            {/*/>*/}
                            {/*<Route exact path="/certification" element={*/}
                            {/*    <RequireAuth permission={1}>*/}
                            {/*        <Cert />*/}
                            {/*    </RequireAuth>}*/}
                            {/*/>*/}
                            {/*<Route exact path="/willingness" element={*/}
                            {/*    <RequireAuth permission={1}>*/}
                            {/*        <Willingness />*/}
                            {/*    </RequireAuth>}*/}
                            {/*/>*/}
                            {/*<Route exact path="/result" element={*/}
                            {/*    <RequireAuth permission={0}>*/}
                            {/*        <Result />*/}
                            {/*    </RequireAuth>}*/}
                            {/*/>*/}
                            {
                                pluginRoutes.map(route => {
                                    return (
                                        <Route key={route.path} exact={route.exact} path={route.path} element={route.element}></Route>
                                    )
                                })
                            }
                            <Route exact path={AUTH_PLUGIN_URI} element={<AuthPlugin />}/>
                            <Route exact path={WILLAUTH_PLUGIN_URI} element={<WillAuthPlugin />}/>
                            <Route exact path={SEAL_PLUGIN_URI} element={<SealPlugin />}/>
                            <Route path="*" element={<Navigate to="/info" replace />} />
                        </Routes>
                    )
                }
            </BrowserRouter>
        </div>
    )
}

// function RequireAuth({ children, permission }) {
//     const stats = getStore('userStatus');
//     return stats >= permission ? children : <Navigate to={'/info'} replace />;
// }
