import {useCallback, useEffect, useRef, useState} from 'react';
import {Skeleton} from "antd";
import styles from './index.module.less';
import {getStore} from "../../../store";
import {AUTH_PLUGIN_URI} from "../../../App";
import {notifyAuthResult, reLogin, resizeDialog, updateUserInfoForContainer} from "../Model";
import {globalFn_getPdfInfo} from "../service";
import {px2pt, sizeAdapter} from "../utils/util";
import {AUTH_CHECK_STATUS, useAuthCheckCache} from "./useAuthCheckCache";
import {getParams} from "../../../utils";
import {setCacheForPhantom} from "../../../request/phantom";

const pluginMsgHandler = (event, callback) => {
    const { data } = event;
    const pluginName = ["esignCNAuthPlugin"];
    if (pluginName.includes(data.name)) {
        callback(data);
    }
}

const generateOrgAuthEntranceUrl = () => {
    const checkParams = {}
    checkParams.client = 2;
    checkParams.token = getStore('userInfo').token || "";
    checkParams.isOut = 1;
    checkParams.companyName = "setting";
    checkParams.forceCompany = 1;
    checkParams.type = 1;
    const authCheckUrl = `${window.location.origin}${AUTH_PLUGIN_URI}?${Object.keys(checkParams).map(key => `${key}=${checkParams[key]}`).join('&')}`
    return authCheckUrl;
};

const generateAuthPromotionEntranceUrl = () => {
    const checkParams = {}
    checkParams.client = 2;
    checkParams.token = getStore('userInfo').token || "";
    checkParams.isOut = 1;
    checkParams.forceCompany = 0;
    checkParams.type = 4;
    checkParams.from = 'phantom-promotion';
    if(getStore('userInfo').enterpriseId) {
        checkParams.enterpriseId = getStore('userInfo').enterpriseId;
    }
    const authCheckUrl = `${window.location.origin}${AUTH_PLUGIN_URI}?${Object.keys(checkParams).map(key => `${key}=${checkParams[key]}`).join('&')}`
    return authCheckUrl;
}

export default function PureAuthComp(props) {
    const { wardType, checkSignFlow, orgAuthEntrance, authPromotion, endProcessCallback } = props;

    const [iframeHasLoad, setIframeHasLoad] = useState(false)
    const [authCheckUrl, setAuthCheckUrl] = useState('');

    const authCheckUrlRef = useRef();

    const [checkCacheStatus] = useAuthCheckCache({authCheckUrl});

    const updateAuthCheckUrlFn = (url) => {
        authCheckUrlRef.current = url;
        setAuthCheckUrl(url);
    }


    const generateAuthCheckUrl = async ({ wardType, checkSignFlow, orgAuthEntrance, authPromotion }) => {

        if (typeof wardType !== 'number') return;

        if (orgAuthEntrance === 1) {
            const url = generateOrgAuthEntranceUrl();
            updateAuthCheckUrlFn(url);
            return;
        }
        if (authPromotion === 1) {
            const url = generateAuthPromotionEntranceUrl();
            updateAuthCheckUrlFn(url);
            return;
        }
        const checkParams = {}
        checkParams.client = 2;
        checkParams.token = getStore('userInfo').token || "";
        checkParams.isOut = wardType === 0 ? 1 : 0;
        checkParams.enterpriseId = getStore('userInfo').enterpriseId || "";
        const forceCompany = getParams() ? getParams()['forceCompany'] || 0 : 0;
        if (checkSignFlow) { // 流程签校验
            await globalFn_getPdfInfo(); // 获取流程签参与信息
            const pdfInfo = getStore('plugin').pdfInfo;
            const curItemInfo = pdfInfo.receipts[pdfInfo.currOrder];
            const { company, email, userName, phoneNumber, companyId } = curItemInfo;
            checkParams.type = 2;
            checkParams.processId = pdfInfo.configInfo.processId || '';
            checkParams.email = email || "";
            checkParams.mobile = phoneNumber || "";
            checkParams.name = userName || "";
            checkParams.companyName = company || "";
            checkParams.companyId = companyId || "";
        } else { // 自签 / 发起校验
            checkParams.type = 1;
            checkParams.forceCompany = forceCompany;
            checkParams.companyName = checkParams.companyName || ""; // 一定要有cn这个key ESIGNCNRD-2540
            checkParams.mobile = "";
            checkParams.name = "";
            checkParams.email = "";
            checkParams.companyId = "";
        }
        const authCheckUrl = `${window.location.origin}${AUTH_PLUGIN_URI}?${Object.keys(checkParams).map(key => `${key}=${checkParams[key]}`).join('&')}`
        updateAuthCheckUrlFn(authCheckUrl);
    }

    const closeDialogByPhantom = useCallback((dialogId) => {
        const ESIGNCN_AUTH_FOR_PHANTOM_DIALOG = 19;
        if (parseInt(dialogId, 10) === ESIGNCN_AUTH_FOR_PHANTOM_DIALOG) {
            notifyAuthResult({ success: false }).then(() => {
                return endProcessCallback({ success: false })
            })
        }
    }, [endProcessCallback])

    const receiveMessage = useCallback((data) => {
        if (data) {
            const { type, relogin, reload, success, enterpriseId, setName } = data;
            if (success) {
                setCacheForPhantom({ type: 1, key: authCheckUrlRef.current, data: { success } });
                let task = Promise.resolve();
                if (enterpriseId) {
                    task = updateUserInfoForContainer({ enterpriseId })
                }
                delete data.name;
                delete data.from;
                delete data.type;
                delete data.enterpriseId; // 该字段在phantomAuth组件中 web通过接口updateUserInfoForPhantom已经替客户端完成缓存处理，故不需要传给客户端；
                task.then(() => {
                    notifyAuthResult(data).then(() => {
                        return endProcessCallback({ success: true })
                    })
                })
            }
            if (relogin) {
                reLogin();
            }
            if (reload) {
                window.location.reload();
            }
            if (type === 'resize') {
                const dialogHeaderHeight = 66;
                const { width, height } = data;
                const widthPt = px2pt(parseInt(width));
                const heightPt = px2pt(parseInt(height) + dialogHeaderHeight);
                resizeDialog({ width: sizeAdapter(widthPt), height: sizeAdapter(heightPt) });
            }
            if (type === 'close' && !success) {
                notifyAuthResult({ success: false }).then(() => {
                    return endProcessCallback({ success: false, setName })
                })
            }
        }
    }, [endProcessCallback])

    useEffect(() => {
        if (checkCacheStatus === AUTH_CHECK_STATUS.SUCCESS) {
            endProcessCallback({ success: true });
        }
    }, [checkCacheStatus, endProcessCallback])


    useEffect(() => {
        window.eSignCN_closeDialogCallback = closeDialogByPhantom;
        generateAuthCheckUrl({ wardType, checkSignFlow, orgAuthEntrance, authPromotion });
    }, [wardType, checkSignFlow, orgAuthEntrance, authPromotion, receiveMessage, closeDialogByPhantom])

    useEffect(() => {
        window.addEventListener('message', (event) => {
            pluginMsgHandler(event, receiveMessage)
        })
        return () => {
            window.removeEventListener('message', (event) => {
                pluginMsgHandler(event, receiveMessage)
            })
        }
    }, [])

    // 处理iframe onload事件，加载时，展示loading组件
    const handleIframeLoadSuccess = () => {
        setIframeHasLoad(true)
    }
    const handleIframeLoadFail = (error) => {
        Promise.reject({ e: 'iframe load error', error })
    }

    const iframeClassName = iframeHasLoad ? `${styles.pluginContainer} ${styles.fullScreen}` : styles.pluginContainer;

    return <div className={`${styles.pluginBackground}`}>
        <div className={`${styles.skeletonWarp}`}><Skeleton/></div>
        {
            authCheckUrl &&
            <iframe
                title='phantom-auth-iframe'
                className={`${iframeClassName}`}
                src={authCheckUrl}
                allow="camera"
                onLoad={handleIframeLoadSuccess}
                onError={handleIframeLoadFail}>
            </iframe>
        }
    </div>
}