import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import {inOnline, inPhantom} from "../pages/PublicPage/utils/util";

export const LOCAL_HOST_KEY = 'localhost'
export const DEVAZK8S_HOST_KEY = 'devcn'
export const STGCN_HOST_KEY = 'stgcn'
export const PRODUCTION_HOST_KEY = 'foxitsoftware'

export const PC_CONTAINER = 'PC';
export const PHANTOM_CONTAINER = 'Phantom';
export const ONLINE_CONTAINER = 'Online';

export const ENV = {
    LOCAL: 'localhost',
    DEVAZK8S: 'devazk8s',
    STGCN: 'stgcn',
    PRODUCTION: 'production',
    UNKNOW: 'unknow'
}
export function getHostEnv() {
    // 获取当前网页域名
    const host = window.location.host;
    if(host.indexOf(LOCAL_HOST_KEY) > -1) {
        return ENV.LOCAL;
    } else if (host.indexOf(DEVAZK8S_HOST_KEY) > -1) {
        return ENV.DEVAZK8S;
    } else if (host.indexOf(STGCN_HOST_KEY) > -1) {
        return ENV.STGCN;
    } else if (host.indexOf(PRODUCTION_HOST_KEY) > -1) {
        return ENV.PRODUCTION;
    } else {
        return ENV.UNKNOW;
    }
}

export function initErrorMonitorTool() {
    if ([ENV.DEVAZK8S, ENV.STGCN, ENV.PRODUCTION, ENV.UNKNOW].includes(getHostEnv())) {
        Sentry.init({
            //用来配置告警事件的上报地址，每个项目的 dsn 值是唯一的
            dsn: 'https://1fcf1f3c9d51960df4ac6f4f7d151cad@sentry-cn.foxitsoftware.cn/9',
            integrations: [new BrowserTracing()],
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            //配置性能监控采样率，默认值为 1.0。值为 0 时不会影响异常数据的上报
            tracesSampleRate: 1,
            //配置应捕获的面包屑总量（控制台日志数量），默认为 100，超过总量的面包屑会被丢弃
            maxBreadcrumbs: 8,
            //数据规范化时对象的最大深度，默认值为 3。超过这个深度的数据都会被丢弃，不会进一步进行遍历
            normalizeDepth: 6,
            //数据规范化时对象或数组中的属性或条目的最大数量，默认值为 1000，超过这个值的数据都会被丢弃，不会进一步进行遍历
            normalizeMaxBreadth: 30,
            release: '1.0.0',
            //配置告警数据关联的环境信息，用于区分不同环境，如 PC#devazk8s / Phantom#stgcn / Online#production
            environment: `${getRunTimeContainerEnv()}#${getHostEnv()}`,
        });
    }
}

export function getRunTimeContainerEnv() {
    if (inPhantom()) {
        return PHANTOM_CONTAINER;
    } else if(inOnline()) {
        return ONLINE_CONTAINER;
    } else {
        return PC_CONTAINER;
    }
}

export const PLUGIN_ROUTES = ['/auth']